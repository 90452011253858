/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "components/layout"
import { Hero, Numbers, President, Timeline, Topics } from "components/sections"

const Section = ({ children }) => <section sx={{ my: 4 }}>{children}</section>

const IndexPage = () => (
  <Layout
    pageTitle="2018-2019 Sustainability Report"
    description="2018-2019 Sustainability Report for Mohawk College."
  >
    <Hero />
    <section>
      <Numbers />
    </section>
    <Section>
      <President />
    </Section>
    <Section>
      <Timeline />
    </Section>
    <Section>
      <Topics />
    </Section>
  </Layout>
)

export default IndexPage
